<template>
    <div class="chart">
        <div class="item">
            <p>维度图表</p>
            <div class="searchBtn">
                <el-select v-model="value" placeholder="请选择预览图形" size="small" @change="look">
                    <el-option label="柱状图" :value="1">
                    </el-option>
                    <el-option label="折线图" :value="2">
                    </el-option>
                    <el-option label="区域面积图" :value="3"></el-option>
                    <el-option label="多边雷达图" :value="4"></el-option>
                </el-select>
                <!-- <el-button type="primary" size="small" @click="look">预览</el-button> -->
                <el-button type="primary" size="small" @click="submit">确认</el-button>
            </div>
            <div class="content" id="iconContent1"></div>
            <div class="content" id="iconContent2"></div>
            <div class="content" id="iconContent3"></div>
            <div class="content" id="iconContent4"></div>
        </div>
   
    <div class="item">
        <p>单题计数图表</p>
        <div class="searchBtn">
                <el-select v-model="value2" placeholder="请选择预览图形" size="small" @change="look">
                    <el-option label="柱状图" :value="1">
                    </el-option>
                    <el-option label="折线图" :value="2">
                    </el-option>
                    <el-option label="区域面积图" :value="3"></el-option>
                    <el-option label="多边雷达图" :value="4"></el-option>
                </el-select>
                <!-- <el-button type="primary" size="small" @click="look">预览</el-button> -->
                <el-button type="primary" size="small" @click="submit">确认</el-button>
            </div>
            <div class="content" id="iContent1"></div>
            <div class="content" id="iContent2"></div>
            <div class="content" id="iContent3"></div>
            <div class="content" id="iContent4"></div>
    </div>
     </div>
</template>

<script>
import { getNormalList,getNormalProjectList, updateIcon } from '@/api/gauge/chartSetting'
import { getDetail } from '@/api/gauge/gaugeIntroduction.js'
import { getDimensionList,getProjectList } from '@/api/gauge/dimension'
export default {
    data() {
        return {
            value: '',
            value2: '',
            yList: [],
            xList: [],
            list: [],
            yList2: [],
            xList2: [],
            list2: [],
            DimensionList: [],
            DimensionList2: [], // 单题计数
            // showType: 1
        }
    },
    methods: {
        // 获取维度列表。提取当前维度是否显示的字段
        async getDimensionsList() {
            const res = await getDimensionList({ id: sessionStorage.getItem('gaugeId') });
            console.log(res);
            if (res.code === 200) {
                this.getList();
                // this.getList2();
                this.DimensionList = res.data;
            }
        },
        // 获取维度列表-单题计数。提取当前维度是否显示的字段
        async getDimensionsList2() {
            const res = await getProjectList(sessionStorage.getItem('gaugeId'));
            console.log(res);
            if (res.code === 200) {
                this.getList2();
                this.DimensionList2 = res.data;
            }
        },
        echartsInit() {
            console.log(this.xList);
            let option = {};

            if (this.value === 1) {
                document.getElementById("iconContent2").style.display = "none";
                document.getElementById("iconContent3").style.display = "none";
                document.getElementById("iconContent4").style.display = "none";
                document.getElementById("iconContent1").style.display = "block";
                option = {
                    title: {
                        // text: "图表显示"
                    },
                    tooltip: {},
                    xAxis: {

                    },
                    yAxis: { data: this.xList },
                    series: [{
                        name: '分值',
                        type: 'bar',
                        data: this.yList,
                        color: '#5B87A3'
                    }],
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    }
                };
                let myEchart = this.$echarts.init(document.getElementById("iconContent1"));

                myEchart.setOption(option);
            } else if (this.value === 2) {
                document.getElementById("iconContent2").style.display = "block";
                document.getElementById("iconContent3").style.display = "none";
                document.getElementById("iconContent4").style.display = "none";
                document.getElementById("iconContent1").style.display = "none";
                // icon = 'line'
                option = {
                    title: {
                        text: "图表显示"
                    },
                    tooltip: {},
                    xAxis: {

                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    yAxis: { data: this.xList },
                    series: [{
                        name: '分值',
                        type: 'line',
                        data: this.yList,
                        color: '#5B87A3'
                    }]
                };
                let myEchart = this.$echarts.init(document.getElementById("iconContent2"));

                myEchart.setOption(option);
            } else if (this.value === 3) {
                document.getElementById("iconContent2").style.display = "none";
                document.getElementById("iconContent3").style.display = "block";
                document.getElementById("iconContent4").style.display = "none";
                document.getElementById("iconContent1").style.display = "none";
                option = {
                    xAxis: {

                    },
                    yAxis: {
                        data: this.xList
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    series: [
                        {
                            data: this.yList,
                            type: 'line',
                            areaStyle: {
                            }
                        }
                    ]
                };
                let myEchart = this.$echarts.init(document.getElementById("iconContent3"));

                myEchart.setOption(option);
            } else if (this.value === 4) {
                document.getElementById("iconContent2").style.display = "none";
                document.getElementById("iconContent3").style.display = "none";
                document.getElementById("iconContent4").style.display = "block";
                document.getElementById("iconContent1").style.display = "none";
                option = {
                    // title: {
                    //   text: '素质能力',
                    //   left: 'center',
                    //   bottom: 'bottom',
                    //   textStyle: {
                    //     fontSize: 14
                    //   }
                    // },
                    radar: {
                        // radius: 68, // 雷达图半径
                        axisName: {
                            color: '#555'
                        },
                        // shape: 'circle',
                        // indicator: [
                        //   { name: '学习能力', max: 100 }, // 上右下左
                        //   { name: '协作能力', max: 100 },
                        //   { name: '代码规范', max: 100 },
                        //   { name: '执行能力', max: 100 },
                        //   { name: '管理能力', max: 100 },
                        //   { name: '沟通能力', max: 100 }
                        // ],
                        indicator: this.list.map(function (item) {
                            item.name = item.name;
                            item.max = 10;
                            return item;
                        }),
                        splitArea: {
                            areaStyle: {
                                color: ['#fff'],
                                shadowColor: 'rgba(0, 0, 0, 0.2)'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: ['#D5DEFD', '#D5DEFD', '#D5DEFD']
                            }
                        }
                    },
                    series: [
                        {
                            name: '多边雷达图',
                            type: 'radar',
                            symbolSize: 10,
                            areaStyle: {
                                normal: {
                                    color: '#DCE8ED'
                                }
                            },
                            data: [
                                {
                                    //   value: [120,130,120,140,141,121,131,151,124,136,133,166],
                                    value: this.yList,
                                    //   value: option.map(function (item) {,
                                    //     return item.sum;
                                    //   }),
                                    itemStyle: {
                                        normal: {
                                            color: '#2FC0E0'
                                        }
                                    },
                                    label: {
                                        show: true,
                                        formatter: this.yList
                                    }
                                }
                            ]
                        }]
                }
                let myEchart = this.$echarts.init(document.getElementById("iconContent4"));

                myEchart.setOption(option);
            }

        },
        echartsInit2() {
            console.log(this.xList2);
            let option = {};

            if (this.value2 === 1) {
                document.getElementById("iContent2").style.display = "none";
                document.getElementById("iContent3").style.display = "none";
                document.getElementById("iContent4").style.display = "none";
                document.getElementById("iContent1").style.display = "block";
                option = {
                    title: {
                        // text: "图表显示"
                    },
                    tooltip: {},
                    xAxis: {

                    },
                    yAxis: { data: this.xList2 },
                    series: [{
                        name: '分值',
                        type: 'bar',
                        data: this.yList2,
                        color: '#5B87A3'
                    }],
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    }
                };
                let myEchart = this.$echarts.init(document.getElementById("iContent1"));

                myEchart.setOption(option);
            } else if (this.value2 === 2) {
                document.getElementById("iContent2").style.display = "block";
                document.getElementById("iContent3").style.display = "none";
                document.getElementById("iContent4").style.display = "none";
                document.getElementById("iContent1").style.display = "none";
                // icon = 'line'
                option = {
                    title: {
                        text: "图表显示"
                    },
                    tooltip: {},
                    xAxis: {

                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    yAxis: { data: this.xList2 },
                    series: [{
                        name: '分值',
                        type: 'line',
                        data: this.yList2,
                        color: '#5B87A3'
                    }]
                };
                let myEchart = this.$echarts.init(document.getElementById("iContent2"));

                myEchart.setOption(option);
            } else if (this.value2 === 3) {
                document.getElementById("iContent2").style.display = "none";
                document.getElementById("iContent3").style.display = "block";
                document.getElementById("iContent4").style.display = "none";
                document.getElementById("iContent1").style.display = "none";
                option = {
                    xAxis: {

                    },
                    yAxis: {
                        data: this.xList2
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    series: [
                        {
                            data: this.yList2,
                            type: 'line',
                            areaStyle: {
                            }
                        }
                    ]
                };
                let myEchart = this.$echarts.init(document.getElementById("iContent3"));

                myEchart.setOption(option);
            } else if (this.value2 === 4) {
                document.getElementById("iContent2").style.display = "none";
                document.getElementById("iContent3").style.display = "none";
                document.getElementById("iContent4").style.display = "block";
                document.getElementById("iContent1").style.display = "none";
                option = {
                    // title: {
                    //   text: '素质能力',
                    //   left: 'center',
                    //   bottom: 'bottom',
                    //   textStyle: {
                    //     fontSize: 14
                    //   }
                    // },
                    radar: {
                        // radius: 68, // 雷达图半径
                        axisName: {
                            color: '#555'
                        },
                        // shape: 'circle',
                        // indicator: [
                        //   { name: '学习能力', max: 100 }, // 上右下左
                        //   { name: '协作能力', max: 100 },
                        //   { name: '代码规范', max: 100 },
                        //   { name: '执行能力', max: 100 },
                        //   { name: '管理能力', max: 100 },
                        //   { name: '沟通能力', max: 100 }
                        // ],
                        indicator: this.list.map(function (item) {
                            item.name = item.name;
                            item.max = 10;
                            return item;
                        }),
                        splitArea: {
                            areaStyle: {
                                color: ['#fff'],
                                shadowColor: 'rgba(0, 0, 0, 0.2)'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: ['#D5DEFD', '#D5DEFD', '#D5DEFD']
                            }
                        }
                    },
                    series: [
                        {
                            name: '多边雷达图',
                            type: 'radar',
                            symbolSize: 10,
                            areaStyle: {
                                normal: {
                                    color: '#DCE8ED'
                                }
                            },
                            data: [
                                {
                                    //   value: [120,130,120,140,141,121,131,151,124,136,133,166],
                                    value: this.yList2,
                                    //   value: option.map(function (item) {,
                                    //     return item.sum;
                                    //   }),
                                    itemStyle: {
                                        normal: {
                                            color: '#2FC0E0'
                                        }
                                    },
                                    label: {
                                        show: true,
                                        formatter: this.yList2
                                    }
                                }
                            ]
                        }]
                }
                let myEchart = this.$echarts.init(document.getElementById("iContent4"));

                myEchart.setOption(option);
            }

        },
        look() {
            // console.log(document.getElementById("iconContent").firstElementChild);
            // document.getElementById("iconContent").firstElementChild.innerHTML = ''
            // document.getElementById("iconContent").innerHTML = ''
            this.echartsInit();
            this.echartsInit2();
        },
        // 量表详情
        async getGaugeDetail() {
            const res = await getDetail({ id: sessionStorage.getItem('gaugeId') })
            console.log(res);
            if (res.code === 200) {
                this.value = res.data.type;
                this.value2 = res.data.chart;

            }
        },
        // 获取图表上的数据
        async getList() {
            const res = await getNormalList({
                id: Number
                    (sessionStorage.getItem('gaugeId'))
            });
            console.log(res);
            let list = [];
            if (res.code === 200) {
                // console.log(this.DimensionList)
                res.data.forEach((item, index) => {
                    // console.log(Object.keys(item)[0])
                    console.log(this.DimensionList[index].isShow)
                    if (this.DimensionList[index].isShow === 'true' || this.DimensionList[index].isShow === true) {
                        this.xList.push(Object.keys(item)[0]);
                        // this.radarList.push({name:})
                        list.push(item[Object.keys(item)[0]]);
                    }

                })
            }
            list.forEach((item, index) => {
                if (item.length > 0) {
                    this.list.push({ name: this.xList[index], value: item[0].maxRange });
                    this.yList.push(item[0].maxRange);

                }
            })
            // console.log(this.yList);
            // console.log(this.list);
            this.echartsInit();
            // this.echartsInit2();
        },
        // 获取图表上的数据
        async getList2() {
            const res = await getNormalProjectList({
                id: Number
                    (sessionStorage.getItem('gaugeId'))
            });
            console.log(res);
            let list = [];
            if (res.code === 200) {
                // console.log(this.DimensionList)
                res.data.forEach((item, index) => {
                    // if (this.DimensionList2[index].isShow === 'true' || this.DimensionList2[index].isShow === true) {
                        this.xList2.push(Object.keys(item)[0]);
                        // this.radarList.push({name:})
                        list.push(item[Object.keys(item)[0]]);
                    // }

                })
            }
            // console.log(list)
            // console.log(this.xList2)
            list.forEach((item, index) => {
                if (item.length > 0) {
                    this.list2.push({ name: this.xList2[index], value: item[0].count });
                    this.yList2.push(item[0].count);

                }
            })
            this.xList2 = this.xList2.reverse()
            // console.log(this.yList2);
            // console.log(this.xList2);
            // this.echartsInit();
            this.echartsInit2();
        },
        // 确认图形
        async submit() {
            const res = await updateIcon({
                id: Number
                    (sessionStorage.getItem('gaugeId')), type: this.value, chart: this.value2
            });
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '修改成功！',
                    type: 'success'
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '修改失败！',
                    type: 'error'
                });
            }
        }
    },
    created() {
        this.getDimensionsList();
        // this.getList2();
        this.getDimensionsList2();
        // this.getList();
        this.getGaugeDetail();
    }
}
</script>

<style lang="scss" scoped>
.chart {
    margin-top: 30px;
    display: flex;
    .item{
        width: 50%;
        p{
            margin: 0;
            margin-bottom: 10px;
            font-weight: 600;
        }
    }
    .content {
        margin-top: 20px;
        height: 500px;
        width: 600px;
        // background: #5B87A3;
    }
}
</style>