// 图形设置
import request from '@/utils/request'
// 获取所有常模
export function getNormalList(params) {
  return request({
    url: '/gauge/normList',
    method: 'get',
    params
  });
}
// 获取所有常模-单题计数
export function getNormalProjectList(params) {
  return request({
    url: '/gauge/countDetailList',
    method: 'get',
    params
  });
}
// 修改量表图形
export function updateIcon(data) {
    return request({
      url: '/gauge',
      method: 'post',
      data
    });
}